import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FixedPlacementErrorMsgService,
  FixedPlacementErrorMsg,
} from '@capsa/services/fixed-placement-error-msg-service';

@Component({
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent implements OnInit, OnDestroy {
  @Input()
  public titleTag: string;

  public alert: FixedPlacementErrorMsg;

  private subs = new Subscription();

  constructor(private alertService: FixedPlacementErrorMsgService) {}

  ngOnInit() {
    this.subs.add(
      this.alertService.alert$.subscribe((alert) => {
        this.alert = alert;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
