import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { PermissionService } from 'src/app/modules/services/permissions';
import { AuthService } from 'src/app/modules/services/auth/auth.service';
import { ApiModule } from 'src/app/modules/api/api.module';
import { UserService } from 'src/app/modules/services/user';
import { FixedPlacementErrorMsgService } from '@capsa/services/fixed-placement-error-msg-service';
import { ToasterService } from '@capsa/services/toaster/toaster.service';
import { RedirectService } from 'src/app/modules/services/redirect';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';

@NgModule({
  imports: [ApiModule],
  providers: [
    CookieService,
    AuthService,
    PermissionService,
    UserService,
    FixedPlacementErrorMsgService,
    ToasterService,
    RedirectService,
    AppSettingsService,
  ],
  exports: [ApiModule],
})
export class ServicesModule {}
