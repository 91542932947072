import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/modules/services/auth/auth.service';
import { ApiResponse } from 'src/app/modules/api/auth';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionService } from 'src/app/modules/services/permissions';
import { FixedPlacementErrorMsgService } from '@capsa/services/fixed-placement-error-msg-service';
import { RedirectService } from 'src/app/modules/services/redirect';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';

export interface ApplicationOption {
  name: string;
  url: string;
}

export const LoginStorageKeys = {
  rememberMe: 'rememberMe',
  userName: 'rememberMeUserName',
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'Capsa Portal';
  passwordInputType = 'password';

  userTextbox = new UntypedFormControl(null, [
    Validators.required,
    Validators.nullValidator,
  ]);

  password = new UntypedFormControl(null, [
    Validators.required,
    Validators.nullValidator,
  ]);

  rememberMeFormControl = new UntypedFormControl(true);
  rememberMeVal = true;

  form = new UntypedFormGroup({
    user: this.userTextbox,
    password: this.password,
    rememberMe: this.rememberMeFormControl,
  });

  @ViewChild('usernameInput', { read: ElementRef })
  userNameInputEl: ElementRef;

  @ViewChild('passwordInput', { read: ElementRef })
  passwordInputEl: ElementRef;

  public loading = false;
  public redirecting = false;

  public supportLoginUrl: string;

  public applicationOptions: ApplicationOption[];

  private subs = new Subscription();

  private authAndPermissions$ = combineLatest([
    this.authService.isAuthenticated$,
    this.permissionService.isReady$,
  ]);

  public get authAndPermissionsReady$() {
    return this.authAndPermissions$.pipe(
      map((results) => results[0] && results[1])
    );
  }

  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private alertService: FixedPlacementErrorMsgService,
    public redirectService: RedirectService,
    private appSettingsService: AppSettingsService
  ) {}

  ngOnInit() {
    this.rememberMeVal = this.getRememberMePrefFromStorage();
    if (this.rememberMeVal) {
      this.userTextbox.setValue(
        localStorage.getItem(LoginStorageKeys.userName)
      );
    }

    this.supportLoginUrl = this.appSettingsService.get('nexsysUrl');
  }

  ngAfterViewInit() {
    if (!this.userNameInputEl.nativeElement.value) {
      this.userNameInputEl.nativeElement.focus();
    } else if (!this.passwordInputEl.nativeElement.value) {
      this.passwordInputEl.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.alertService.clear();
    this.subs.unsubscribe();
  }

  togglePassword(): void {
    this.passwordInputType =
      this.passwordInputType === 'password' ? 'text' : 'password';
  }

  saveRememberMePrefToStorage(): void {
    localStorage.setItem(
      LoginStorageKeys.rememberMe,
      this.rememberMeVal ? 'true' : 'false'
    );
  }

  rememberMeToggled(): void {
    if (!this.rememberMeVal) {
      localStorage.removeItem(LoginStorageKeys.userName);
    }

    this.saveRememberMePrefToStorage();
  }

  getRememberMePrefFromStorage(): boolean {
    const lsVal = localStorage.getItem(LoginStorageKeys.rememberMe);
    return lsVal === null || lsVal === 'true';
  }

  async onLogin() {
    try {
      this.form.disable();

      this.loading = true;
      this.alertService.clear();

      const result = await this.authService.login(
        this.userTextbox.value,
        this.password.value
      );

      if (!result) {
        this.loading = false;
        this.form.enable();
        this.alertService.set({ text: 'COM_API_ERROR', severity: 'error' });
        return;
      }

      if (result.IsPasswordResetRequested) {
        this.authService.logout();
        this.loading = false;
        this.form.enable();
        // we use this error to avoid account enumeration by making it seem
        // like the username/password combo is invalid when it isn't.
        this.alertService.set({
          text: 'LOGIN_PAGE_INVALID_USERNAME_OR_PASSWORD',
          severity: 'error',
        });
        return;
      }

      if (this.rememberMeVal) {
        localStorage.setItem(LoginStorageKeys.userName, this.userTextbox.value);
      }

      this.showOptionsOrRedirect();
    } catch (e) {
      let errorText: string = null;

      if (e.Success === false) {
        const error: ApiResponse<any> = e;

        if (error.Message.indexOf('User Name') >= 0) {
          errorText = 'LOGIN_PAGE_INVALID_USERNAME_OR_PASSWORD';
        } else {
          errorText = error.Message;
        }
      } else {
        errorText = 'COM_API_ERROR';
      }

      this.alertService.set({ text: errorText, severity: 'error' });

      this.loading = false;
      this.redirecting = false;
      this.form.enable();
    }
  }

  private showOptionsOrRedirect() {
    const sub = this.authAndPermissionsReady$.subscribe((ready) => {
      if (ready) {
        const applicationOptions =
          this.redirectService.getApplicationAccessList(
            this.permissionService.userPermissions
          );

        if (applicationOptions.length === 1) {
          // means they only have one application they can access, so navigate right away.
          this.redirectService.navigateToApplication(applicationOptions[0]);
          return;
        }

        if (applicationOptions.length === 0) {
          // no available options, yet the person is valid. This means the must be
          // some sort of nexsys user so just redirect to that application.
          this.redirectService.navigateToNexsys();
          return;
        }

        if (applicationOptions.length > 1) {
          const nsight = applicationOptions.find(
            (x) => x.name === RedirectService.nsightName
          );

          if (nsight) {
            this.redirectService.navigateToApplication(nsight);
            return;
          }
        }

        this.applicationOptions = applicationOptions;
      }
    });
    this.subs.add(sub);
  }
}
