import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  PasswordFormChangeEvent,
  SetPasswordForm,
} from '../set-password.component';
import { Subscription } from 'rxjs';
import { questionCircleIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-set-password-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
})
export class InputsComponent implements OnInit, OnDestroy {
  public newPasswordErrorTag: string | null = null;
  public repeatPasswordErrorTag: string | null = null;

  private subs = new Subscription();

  public icons = { questionCircleIcon: questionCircleIcon };

  @Input()
  public form: SetPasswordForm;

  @Output()
  public valuesChanged = new EventEmitter<PasswordFormChangeEvent>();

  @Input()
  public requireCurrentPassword: boolean;

  public formValid: boolean;

  private errorTags = {
    hasUpper: 'SET_PASSWORD_PASSWORD_HAS_UPPER_REQUIREMENT',
    hasLower: 'SET_PASSWORD_PASSWORD_HAS_LOWER_REQUIREMENT',
    hasNumber: 'SET_PASSWORD_PASSWORD_HAS_NUMBER_REQUIREMENT',
    hasSpecial: 'SET_PASSWORD_PASSWORD_HAS_SPECIAL_REQUIREMENT',
    minlength: 'SET_PASSWORD_PASSWORD_MIN_LENGTH_REQUIREMENT',
    maxlength: 'SET_PASSWORD_PASSWORD_MAX_LENGTH_REQUIREMENT',
    mustMatch: 'SET_PASSWORD_PASSWORD_MUST_MATCH_REQUIREMENT_ERROR',
  };

  ngOnInit() {
    this.subs.add(
      this.form.controls.newPassword.valueChanges.subscribe(() => {
        this.getNewPasswordError();
        // Check the validity of the "repeat password" control since the value
        // changed on the "new password" control.
        this.form.controls.repeatNewPassword.updateValueAndValidity();
        this.getRepeatPasswordError();
        this.onFormChanged();
      })
    );

    this.subs.add(
      this.form.controls.repeatNewPassword.valueChanges.subscribe(() => {
        this.getRepeatPasswordError();
        this.onFormChanged();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private onFormChanged() {
    if (
      this.newPasswordErrorTag ||
      this.repeatPasswordErrorTag ||
      !this.form.controls.repeatNewPassword.value
    ) {
      return;
    }

    const values: PasswordFormChangeEvent = {
      newPassword: this.form.controls.newPassword.value,
      repeatNewPassword: this.form.controls.repeatNewPassword.value,
    };

    this.valuesChanged.emit(values);
  }

  private getNewPasswordError() {
    const errorKey = Object.keys(
      this.form.controls.newPassword.errors || {}
    )[0];
    this.newPasswordErrorTag = errorKey ? this.errorTags[errorKey] : null;
  }

  private getRepeatPasswordError() {
    const errorKey = Object.keys(
      this.form.controls.repeatNewPassword.errors || {}
    )[0];
    this.repeatPasswordErrorTag = errorKey ? this.errorTags[errorKey] : null;
  }
}
