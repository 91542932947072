import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from 'src/app/modules/layout/content-wrapper/content-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ContentWrapperComponent],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [ContentWrapperComponent],
})
export class LayoutModule {}
