<app-content-wrapper [titleTag]="'SET_PASSWORD_HEADER'">
  <app-security-code-required
    [codeQueryParamName]="'code'"
    [tokenType]="securityTokenType"
    (codeValidated)="onSecurityCodeVerified($event)"
  >
    <ng-container *ngIf="resetPasswordDetails">
      <form id="setPasswordForm" [formGroup]="setPasswordForm">
        <app-set-password
          [form]="setPasswordForm"
          (valuesChanged)="onPasswordUpdate($event)"
        ></app-set-password>

        <div class="form-group">
          <br />
          <button
            [disabled]="!canClickButton"
            (click)="onButtonClicked()"
            kendoButton
            [primary]="true"
            data-testid="btnSetPassword"
          >
            <ng-container *ngIf="loading">
              {{ 'COM_SAVING_DOTS' | translate }}
            </ng-container>
            <ng-container *ngIf="redirecting">
              {{ 'LOGIN_PAGE_REDIRECTING' | translate }}
            </ng-container>
            <ng-container *ngIf="!redirecting && !loading">
              {{ 'SET_PASSWORD_HEADER' | translate }}
            </ng-container>
          </button>
        </div>
      </form>
    </ng-container>
  </app-security-code-required>
</app-content-wrapper>
