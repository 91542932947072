import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from 'src/app/modules/api/base';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';

export interface ApiResponse<TResult> {
  Success: boolean;
  ResponseType: number;
  Message: string;
  MethodName: string;
  ErrorId: number;
  TotalRecordCount: number;
  Result?: TResult;
}

export interface TranslationRequest {
  ProductLine: number;
  Context: string;
}

export interface LoginResponse {
  Success: boolean;
  ResponseType: number;
  Message: string;
  MethodName: string;
  ErrorId: number;
  Result?: {
    EnterpriseId: number;
    IsPasswordResetRequested: boolean;
    LanguageCode: string;
    Token: string;
    UserDisplayName: string;
    IsEnterpriseAdmin: string;
    CountryCode: string;
    EncryptionKey: string;
  };
  TotalRecordCount: number;
}

export interface LoginRequest {
  clientId: string;
  clientSecret: string;
  userName: string;
  password: string;
}

export interface AuthApiInstance {
  login(request: LoginRequest): Promise<LoginResponse>;
}

@Injectable()
export class AuthApi extends BaseApi implements AuthApiInstance {
  protected endpoints = {
    getAuth: this.buildUrl('/auth/getauth'),
  };

  constructor(
    protected http: HttpClient,
    protected appSettingsService: AppSettingsService
  ) {
    super(http, appSettingsService);
  }

  public async login(request: LoginRequest): Promise<LoginResponse> {
    const response = await this.http
      .post<LoginResponse>(this.endpoints.getAuth, request)
      .toPromise();

    if (!response.Success) {
      throw response;
    }
    return response;
  }
}
