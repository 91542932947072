import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { CompletePasswordResetComponent } from 'src/app/modules/user/complete-password-reset/complete-password-reset.component';
import { ConfirmNewEmailComponent } from 'src/app/modules/user/confirm-new-email/confirm-new-email.component';
import { ResetPasswordComponent } from 'src/app/modules/user/reset-password/reset-password.component';
import { UserRegistrationComponent } from 'src/app/modules/user/user-registration/user-registration.component';

const routes: Routes = [
  {
    data: { title: 'Login' },
    component: LoginComponent,
    path: '',
  },
  {
    data: { title: 'Register' },
    component: UserRegistrationComponent,
    path: 'register',
  },
  {
    data: { title: 'Forgot Password' },
    component: ResetPasswordComponent,
    path: 'forgot-password',
  },
  {
    data: { title: 'Reset Password' },
    component: CompletePasswordResetComponent,
    path: 'reset-password',
  },
  {
    data: { title: 'Confirm New Email' },
    component: ConfirmNewEmailComponent,
    path: 'confirm-new-email',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
