import {
  UserApi,
  UserSecurityTokenMetadata,
  UserPasswordUpdateRequest,
  UserPasswordResetRequest,
  UserSecurityTokenType,
} from '../../api/user';
import { ApiResponse } from 'src/app/modules/api/auth';
import { catchError, map, finalize, switchMap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from 'src/app/modules/services/auth/user';
import { AuthService } from 'src/app/modules/services/auth/auth.service';
import { throwIfBadRespReturnPayloadOnly } from '@capsa/api/index';

export interface SecurityQuestionResponse {
  SecurityQuestionId: number;
  SecurityQuestion: string;
}

export interface SecurityQuestionAnswer {
  SecurityQuestionId: number;
  SecurityAnswer: string;
}

export interface VerifiedUserSecurityToken {
  user: User;
  codeMetadata: UserSecurityTokenMetadata;
}

@Injectable()
export class UserService {
  public rawResponse: ApiResponse<any> | undefined;

  constructor(
    private userApi: UserApi,
    private authService: AuthService
  ) {}

  public getSecurityQuestions(): Observable<SecurityQuestionResponse[]> {
    return this.userApi.getSecurityQuestions();
  }

  public parseSecurityCodeMetadata(
    queryParam: string
  ): UserSecurityTokenMetadata {
    try {
      return JSON.parse(
        atob(decodeURIComponent(queryParam))
      ) as UserSecurityTokenMetadata;
    } catch {
      return null;
    }
  }

  public verifySecurityCode(
    details: UserSecurityTokenMetadata,
    tokenType: UserSecurityTokenType
  ) {
    return this.userApi.verifyEmailToken(details, tokenType).pipe(
      map((response) => {
        return true;
      })
    );
  }

  public completeRegistration(
    passwordDetails: UserPasswordUpdateRequest,
    questionAnswers: SecurityQuestionAnswer[]
  ): Observable<ApiResponse<void>> {
    return this.userApi.updateSecurityQuestions(questionAnswers).pipe(
      switchMap((updateQuestionsResponse) => {
        if (!updateQuestionsResponse.Success) {
          // failed to set/update questions, so error out.
          return throwError(updateQuestionsResponse);
        }

        // success, so continue to set the passwords.
        return this.userApi.updatePassword(passwordDetails);
      }),
      switchMap((updatePasswordResponse) => {
        if (!updatePasswordResponse.Success) {
          return throwError(updatePasswordResponse);
        }

        return of(updatePasswordResponse);
      })
    );
  }

  public requestPasswordReset(
    username: string,
    questionAnswer: SecurityQuestionAnswer
  ): Observable<void> {
    const resetPasswordRequest: UserPasswordResetRequest = {
      ReturnUrl: window.location.origin + '/reset-password',
      SecurityQuestionAnswer: questionAnswer.SecurityAnswer,
      SecurityQuestionId: questionAnswer.SecurityQuestionId,
      Username: username,
      TranslationRequest: {
        ProductLine: 3,
        Context: 'portal',
      },
    };

    return this.userApi.requestPasswordReset(resetPasswordRequest);
  }

  public updatePassword(
    username: string,
    oldPassword: string,
    newPassword: string
  ): Observable<User> {
    const updatePasswordRequest: UserPasswordUpdateRequest = {
      Username: username,
      EnterpriseId: this.authService.currentUser.EnterpriseId,
      NewPassword: newPassword,
      OldPassword: oldPassword,
    };

    return this.userApi.updatePassword(updatePasswordRequest).pipe(
      switchMap((updatePasswordResponse) => {
        if (!updatePasswordResponse.Success) {
          // failed to update password, so error out.
          return throwError(updatePasswordResponse);
        }

        // success, so continue to login
        return this.authService.login(
          updatePasswordRequest.Username,
          updatePasswordRequest.NewPassword
        );
      })
    );
  }
}
