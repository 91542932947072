import { Injectable } from '@angular/core';
import { ToasterParams } from '@capsa/services/toaster/toaster-params';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  public showSuccess(translationTag: string, params?: Partial<ToasterParams>) {
    this.show(translationTag, 'success', params);
  }

  public showError(translationTag: string, params?: Partial<ToasterParams>) {
    this.show(translationTag, 'error', params);
  }

  public showStickyNotification(textNotTag: string) {
    this.show(textNotTag, 'info', { closeable: true, hideAfterMs: 600000 });
  }

  private show(
    translationTag: string,
    style: 'success' | 'error' | 'info',
    params?: Partial<ToasterParams>
  ) {
    if (!params) {
      params = {};
    }

    if (typeof translationTag === 'string' && translationTag.length === 0) {
      translationTag = '*** TRANSLATION TAG NOT SET ***';
    }

    this.notificationService.show({
      content: this.translateService.instant(translationTag),
      type: {
        style,
        icon: false,
      },
      position: {
        horizontal: 'center',
        vertical: 'top',
      },
      closable: !!params.closeable,
      hideAfter: params.hideAfterMs,
    });
  }
}
