import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { APP_VERSION } from '../environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'Capsa Portal';
  public currentYear: number;
  public appVersion: string = APP_VERSION;
  public eulaUrl: string;

  constructor(
    private translate: TranslateService,
    private appSettingService: AppSettingsService
  ) {
    this.translate.setDefaultLang('en-US');
    this.appVersion = APP_VERSION;
    this.eulaUrl = this.appSettingService.get('eulaUrl');
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
