<fieldset [formGroup]="form">
  <div class="username">
    <label for="username" class="input-header">{{
      'USERNAME' | translate
    }}</label>
    <br />
    <input
      id="username"
      kendoTextBox
      type="text"
      class="form-control"
      formControlName="username"
      [readOnly]="readonlyUsername"
      data-testid="txtUsername"
      autocomplete="username"
    />
  </div>
  <div class="question">
    <label for="question">{{
      dropDownHeaderTag || 'SEQURITY_QUESTION_HEADER' | translate
    }}</label
    ><br />
    <kendo-dropdownlist
      id="question"
      [loading]="loading"
      [data]="questions"
      [textField]="'SecurityQuestion'"
      [valueField]="'SecurityQuestionId'"
      formControlName="question"
      [valuePrimitive]="true"
      required
      data-testid="ddlSecurityQuestion"
    >
    </kendo-dropdownlist>
  </div>

  <div class="answer">
    <label for="answer">{{
      'SEQURITY_QUESTION_ANSWER_HEADER' | translate
    }}</label>
    <input
      id="answer"
      formControlName="answer"
      name="answer"
      kendoTextBox
      type="text"
      spellcheck="false"
      data-testid="txtSecurityQuestionAnswer"
      autocomplete="off"
    />
  </div>
</fieldset>
