import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type AlertSeverity = 'error' | 'warning' | 'info' | 'success';

export interface FixedPlacementErrorMsg {
  text: string;
  severity: AlertSeverity;
  timeout?: number;
}

@Injectable()
/**
 * Service was renamed from "AlertService" since that name was a bit confusing, making it seem like it's a Popup
 * eventually we'd wanna remove this and replace with something else...
 */
export class FixedPlacementErrorMsgService {
  private currentTimeout: number | null = null;
  private subject: BehaviorSubject<FixedPlacementErrorMsg | null> =
    new BehaviorSubject(null);

  public alert$: Observable<FixedPlacementErrorMsg | null> =
    this.subject.asObservable();

  public clear() {
    this.set(null);
  }

  public set(errorMessage: FixedPlacementErrorMsg) {
    this.subject.next(errorMessage);

    if (this.currentTimeout) {
      window.clearTimeout(this.currentTimeout);
    }
    if (errorMessage && errorMessage.timeout !== undefined) {
      this.currentTimeout = window.setTimeout(() => {
        this.clear();
      }, errorMessage.timeout);
    }
  }
}
