<app-content-wrapper [titleTag]="'CONFIRM_NEW_EMAIL_TITLE'">
  <app-security-code-required
    [codeQueryParamName]="emailConfirmQueryParamName"
    [tokenType]="securityTokenType"
    (codeValidated)="onSecurityCodeVerified()"
    [loadingMessageTag]="'EMAIL_CONFIRM_IN_PROGRESS'"
  >
    <ng-container>
      <div *ngIf="!codeIsValid; else loadedBlock" class="fontSizeMed">
        {{ 'EMAIL_CONFIRM_IN_PROGRESS' | translate }}
      </div>
      <ng-template #loadedBlock>
        <div class="fontSizeMed">
          {{ 'EMAIL_CONFIRM_SUCCESS' | translate }}
        </div>
      </ng-template>
    </ng-container>
  </app-security-code-required>
</app-content-wrapper>
