import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { AuthService } from 'src/app/modules/services/auth/auth.service';

export interface ApplicationOption {
  name: string;
  url: string;
}

@Injectable()
export class RedirectService {
  public static readonly nsightName = 'N-Sight';
  public static readonly nexsysName = 'Nexsys';

  private nSightOption: ApplicationOption = {
    name: RedirectService.nsightName,
    url: this.getNsUrl(),
  };

  private nexsysOption: ApplicationOption = {
    name: RedirectService.nexsysName,
    url: this.getNexsysUrl(),
  };

  protected nsUrl: string;
  protected nexsysUrl: string;

  constructor(
    private authService: AuthService,
    appSettingsService: AppSettingsService
  ) {
    this.nexsysOption.url = appSettingsService.get('nexsysUrl');
    this.nSightOption.url = appSettingsService.get('nsightUrl');
  }

  private getNsUrl(): string {
    return this.nsUrl;
  }

  private getNexsysUrl(): string {
    return this.nexsysUrl;
  }

  public navigateToNSight() {
    this.navigateToApplication(this.nSightOption);
  }

  public navigateToNexsys() {
    this.navigateToApplication(this.nexsysOption);
  }

  public navigateToApplication(option: ApplicationOption) {
    if (option === this.nSightOption) {
      // redirect via URL + token
      const encodedResult = encodeURIComponent(
        JSON.stringify(this.authService.currentUser)
      );
      window.location.href = option.url + `?token=${encodedResult}`;
    } else if (option === this.nexsysOption) {
      // redirect straight up
      window.location.href = option.url;
    }
  }

  public getApplicationAccessList(
    permissions: Set<string>
  ): ApplicationOption[] {
    let careLinkUser = false;
    let nexsysUser = false;

    permissions.forEach((v) => {
      if (v === 'Manage_CrossEnterpiseData_ReadWrite') {
        careLinkUser = true;
        nexsysUser = true;
      }
      if (v.startsWith('CLI_') || v.startsWith('CareLink')) {
        careLinkUser = true;
      } else if (v.startsWith('NEX_') || v.startsWith('Nexsys')) {
        nexsysUser = true;
      }
    });

    const actualOptions = [];

    if (careLinkUser) {
      actualOptions.push(this.nSightOption);
    }
    if (nexsysUser) {
      actualOptions.push(this.nexsysOption);
    }

    return actualOptions;
  }
}
