<app-content-wrapper [titleTag]="'RESET_PASSWORD'">
  <div *ngIf="passwordEmailSent" class="fontSizeMed">
    {{ 'PASSWORD_EMAIL_SENT' | translate }}
  </div>

  <form
    [formGroup]="resetPasswordForm"
    class="reset-password-form"
    *ngIf="!passwordEmailSent"
  >
    <app-security-questions
      [readonlyUsername]="false"
      [form]="resetPasswordForm"
      dropDownHeaderTag="RESET_PASSWORD_SECURITY_QUESTION_HEADER"
      (valuesChanged)="onSecurityQuestionSave($event)"
    ></app-security-questions>

    <div class="form-group">
      <br />
      <button
        [disabled]="!buttonEnabled"
        (click)="onPwdResetSubmit()"
        kendoButton
        [primary]="true"
        data-testid="btnSubmitPasswordReset"
      >
        <ng-container *ngIf="loading">
          {{ 'COM_LOADING' | translate }}
        </ng-container>
        <ng-container *ngIf="!loading">
          {{ 'RESET_PASSWORD' | translate }}
        </ng-container>
      </button>
    </div>
  </form>
</app-content-wrapper>
