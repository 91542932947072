import { Component, OnInit } from '@angular/core';
import { PasswordFormChangeEvent } from 'src/app/modules/user/set-password/set-password.component';
import {
  SecurityQuestionAnswer,
  UserService,
  VerifiedUserSecurityToken,
} from 'src/app/modules/services/user';
import {
  UserSecurityTokenMetadata,
  UserPasswordUpdateRequest,
  UserSecurityTokenType,
} from 'src/app/modules/api/user';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FixedPlacementErrorMsgService } from '@capsa/services/fixed-placement-error-msg-service';
import { RedirectService } from 'src/app/modules/services/redirect';
import { LoginStorageKeys } from 'src/app/modules/login/login.component';
import { UntypedFormGroup } from '@angular/forms';
import { SecurityQuestionData } from 'src/app/modules/user/security-questions/security-questions.component';
import { lockIcon, bookIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent implements OnInit {
  public stepType = 'full';
  public readonly emailConfirmQueryParamName = 'code';
  public current = 0;
  public linear = true;
  public userRegistrationForm: UntypedFormGroup;
  public registering: boolean;
  public redirecting: boolean;
  public registrationTokenType: UserSecurityTokenType =
    UserSecurityTokenType.Registration;

  public get canClickButton() {
    if (this.current === 0) {
      return !!this.securityQuestion;
    }

    if (this.current === 1) {
      return this.setPassword && !this.registering && !this.redirecting;
    }

    return false;
  }

  public registrationDetails: UserSecurityTokenMetadata = undefined;
  private setPassword: PasswordFormChangeEvent = undefined;
  private securityQuestion: SecurityQuestionAnswer = undefined;
  private subs = new Subscription();

  private icons = { bookIcon: bookIcon, lockIcon: lockIcon };

  public steps = [
    {
      label: 'Security Questions',
      svgIcon: this.icons.bookIcon,
      isValid: false,
    },
    {
      label: 'Password',
      svgIcon: this.icons.lockIcon,
      isValid: false,
      disabled: true,
    },
  ];

  public prev() {
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  public next() {
    const previousStep = this.steps[this.current];

    if (this.current < this.steps.length - 1) {
      this.current += 1;
    }

    this.steps[this.current].disabled = false;
    previousStep.disabled = previousStep.isValid = true;
  }

  constructor(
    private userService: UserService,
    private alertService: FixedPlacementErrorMsgService,
    private redirectService: RedirectService
  ) {}

  ngOnInit() {
    this.userRegistrationForm = new UntypedFormGroup({});
  }

  public onSecurityCodeVerified(verificationToken: VerifiedUserSecurityToken) {
    this.registrationDetails = verificationToken.codeMetadata;
  }

  public onButtonClicked() {
    if (this.current !== this.steps.length - 1) {
      return this.next();
    }
    // nothing more to do, just register now.
    this.registerUser();
  }

  public onPasswordUpdate(pwd: PasswordFormChangeEvent) {
    this.setPassword = pwd;
  }

  public onSecurityQuestionUpdate(securityQuestion: SecurityQuestionData) {
    this.securityQuestion = {
      SecurityAnswer: securityQuestion.securityQuestionAnswer,
      SecurityQuestionId: securityQuestion.securityQuestionId,
    };
  }

  private registerUser() {
    this.registering = true;

    const updatePasswordRequest: UserPasswordUpdateRequest = {
      EnterpriseId: this.registrationDetails.enterpriseId,
      Username: this.registrationDetails.username,
      NewPassword: this.setPassword.newPassword,
      OldPassword: this.registrationDetails.password,
    };

    const sub = this.userService
      .completeRegistration(updatePasswordRequest, [this.securityQuestion])
      .pipe(finalize(() => (this.registering = false)))
      .subscribe(
        (_) => {
          this.redirecting = true;

          // Registration complete!
          // clear any "remember me" data.
          this.clearRememberMe();
          this.redirectService.navigateToNSight();
        },
        (_) => {
          this.alertService.set({
            text: 'ERROR_REGISTERING',
            severity: 'error',
          });
        }
      );

    this.subs.add(sub);
  }

  private clearRememberMe() {
    localStorage.removeItem(LoginStorageKeys.rememberMe);
    localStorage.removeItem(LoginStorageKeys.userName);
  }
}
