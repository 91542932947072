import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  UserSecurityTokenMetadata,
  UserSecurityTokenType,
} from 'src/app/modules/api/user';
import { FixedPlacementErrorMsgService } from '@capsa/services/fixed-placement-error-msg-service';
import { RedirectService } from 'src/app/modules/services/redirect';
import {
  UserService,
  VerifiedUserSecurityToken,
} from 'src/app/modules/services/user';
import { PasswordFormChangeEvent } from 'src/app/modules/user/set-password/set-password.component';

@Component({
  selector: 'app-complete-password-reset',
  templateUrl: './complete-password-reset.component.html',
  styleUrls: ['./complete-password-reset.component.scss'],
})
export class CompletePasswordResetComponent implements OnInit, OnDestroy {
  public redirecting: boolean;
  public loading: boolean;
  public setPasswordForm: UntypedFormGroup;
  public resetPasswordDetails: UserSecurityTokenMetadata;
  public securityTokenType: UserSecurityTokenType =
    UserSecurityTokenType.ResetPassword;

  private selectedPassword: PasswordFormChangeEvent | undefined;
  private subs = new Subscription();

  public get canClickButton() {
    return !this.loading && !this.redirecting && this.setPasswordForm.valid;
  }

  constructor(
    private userService: UserService,
    private redirectService: RedirectService,
    private alertService: FixedPlacementErrorMsgService
  ) {}

  ngOnInit() {
    this.setPasswordForm = new UntypedFormGroup({});
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onSecurityCodeVerified(verificationToken: VerifiedUserSecurityToken) {
    this.resetPasswordDetails = verificationToken.codeMetadata;
  }

  public onPasswordUpdate(pwd: PasswordFormChangeEvent) {
    this.selectedPassword = pwd;
  }

  public onButtonClicked() {
    this.setPassword();
  }

  private setPassword() {
    this.loading = true;

    const sub = this.userService
      .updatePassword(
        this.resetPasswordDetails.username,
        this.resetPasswordDetails.password,
        this.selectedPassword.newPassword
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (_) => {
          this.redirecting = true;
          this.redirectService.navigateToNSight();
        },
        (_) => {
          this.alertService.set({
            text: 'ERROR_SETTING_PASSWORD',
            severity: 'error',
          });
        }
      );

    this.subs.add(sub);
  }
}
