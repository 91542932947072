<div id="loginCard" class="card">
  <img src="../../../assets/images/capsa_logo.svg" />
  <h1
    [ngClass]="{
      'title-with-error': !!alert
    }"
  >
    {{ titleTag | translate }}
  </h1>

  <div *ngIf="alert" class="error-wrapper">
    <label
      [class.error]="alert.severity === 'error'"
      [class.success]="alert.severity === 'success'"
      >{{ alert.text | translate }}</label
    ><br />
  </div>

  <ng-content></ng-content>
</div>
