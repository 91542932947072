<ng-container
  *ngIf="
    (authAndPermissionsReady$ | async) &&
    applicationOptions &&
    applicationOptions.length > 0
  "
>
  <app-content-wrapper [titleTag]="'SELECT_APPLICATION'">
    <div class="padTop application-selection-wrapper">
      <app-login-application-selection
        [options]="applicationOptions"
        (optionSelected)="redirectService.navigateToApplication($event)"
      ></app-login-application-selection>
    </div>
  </app-content-wrapper>
</ng-container>
<ng-container *ngIf="(authAndPermissionsReady$ | async) === false">
  <app-content-wrapper [titleTag]="'WELCOME'">
    <form [target]="form">
      <fieldset>
        <label for="user">{{ 'USERNAME' | translate }}</label>
        <input
          #usernameInput
          name="user"
          [formControl]="userTextbox"
          kendoTextBox
          type="text"
          spellcheck="false"
        />
        <div class="block-wrapper">
          <input
            id="chkRememberMe"
            tabindex="-1"
            type="checkbox"
            name="rememberMe"
            [(ngModel)]="rememberMeVal"
            (change)="rememberMeToggled()"
            kendoCheckBox
          />
          <label class="k-checkbox-label accent-text" for="chkRememberMe">{{
            'REMEMBER_ME' | translate
          }}</label>
        </div>
        <br />
        <label for="password">{{ 'PASSWORD' | translate }}</label>
        <div id="pwdInputWrapper">
          <input
            #passwordInput
            name="password"
            [type]="passwordInputType"
            [formControl]="password"
            kendoTextBox
          />
          <i
            class="icon-eye icon togglePassword"
            (click)="togglePassword()"
          ></i>
        </div>
        <div class="block-wrapper">
          <span class="accent-text">{{ 'FORGOT_PASSWORD' | translate }}</span
          >&nbsp;
          <a routerLink="/forgot-password" tabindex="-1">{{
            'PASSWORD_RESET' | translate
          }}</a>
        </div>
      </fieldset>
      <div *ngIf="loading"></div>
      <button
        [disabled]="!form.valid || loading"
        (click)="onLogin()"
        kendoButton
        [primary]="true"
      >
        <ng-container *ngIf="loading && !redirecting">
          {{ 'LOGIN_PAGE_LOGGING_IN' | translate }}
        </ng-container>
        <ng-container *ngIf="redirecting">
          {{ 'LOGIN_PAGE_REDIRECTING' | translate }}
        </ng-container>
        <ng-container *ngIf="!redirecting && !loading">
          {{ 'SIGN_IN' | translate }}
        </ng-container>
      </button>
    </form>
    <div id="imSupportWrapper">
      {{ 'IM_SUPPORT' | translate }}&nbsp;
      <a href="{{ supportLoginUrl }}">{{ 'SUPPORT_LOGIN' | translate }}</a>
    </div>
  </app-content-wrapper>
</ng-container>
