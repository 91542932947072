import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';

export interface ApiResult<TResult> {
  Result?: TResult;
}

export abstract class BaseApi {
  protected abstract endpoints: { [apiName: string]: string };

  protected baseUrl: string;

  constructor(
    protected http: HttpClient,
    appSettingService: AppSettingsService
  ) {
    this.baseUrl = appSettingService.get('apiUrl');
  }

  protected buildUrl(relativeUrl: string) {
    const separator =
      this.baseUrl.endsWith('/') || relativeUrl.startsWith('/') ? '' : '/';

    return `${this.baseUrl}${separator}${relativeUrl}`;
  }
}
