import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationSelectionComponent } from './application-selection/application-selection.component';
import { CoreModule } from 'src/app/modules/core/core.module';
import { UserModule } from 'src/app/modules/user/user.module';

@NgModule({
  declarations: [LoginComponent, ApplicationSelectionComponent],
  imports: [CoreModule, UserModule, TranslateModule.forChild()],
  exports: [LoginComponent],
})
export class LoginModule {}
