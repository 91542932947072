import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserSecurityTokenType } from 'src/app/modules/api/user';
import { VerifiedUserSecurityToken } from 'src/app/modules/services/user';

@Component({
  selector: 'app-confirm-new-email',
  templateUrl: './confirm-new-email.component.html',
  styleUrls: ['./confirm-new-email.component.scss'],
})
export class ConfirmNewEmailComponent implements OnDestroy {
  public codeIsValid: boolean;
  public readonly emailConfirmQueryParamName = 'code';
  public readonly securityTokenType = UserSecurityTokenType.ConfirmEmailAddress;

  private subs = new Subscription();

  constructor() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onSecurityCodeVerified() {
    this.codeIsValid = true;
  }
}
