import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from './user';
import { LoginRequest, AuthApi } from '../../api/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';

/**
 * Represents the methods avaialbe to an instance
 * of an AuthService.
 */
export interface AuthServiceInstance {
  currentUser: User;
  login(username: string, password: string): Promise<User | undefined>;
  logout(): void;
  hasAccessToken(): boolean;
  getDisplayName(): string;
}

@Injectable()
export class AuthService implements AuthServiceInstance {
  currentUser: User;

  private isAuthenticatedBehSub: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isAuthenticated$: Observable<boolean> =
    this.isAuthenticatedBehSub.asObservable();

  constructor(
    private authApi: AuthApi,
    private router: Router,
    private appSettingsService: AppSettingsService
  ) {}

  public updateIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticatedBehSub.next(isAuthenticated);
  }

  async login(username: string, password: string): Promise<User | undefined> {
    const request: LoginRequest = {
      password,
      userName: username,
      clientId: this.appSettingsService.get('clientId'),
      clientSecret: this.appSettingsService.get('clientSecret'),
    };

    const response = await this.authApi.login(request);

    if (!response.Result) {
      return undefined;
    }

    this.currentUser = {
      AccessToken: response.Result.Token,
      DisplayName: response.Result.UserDisplayName,
      UserName: response.Result.UserDisplayName,
      EnterpriseId: response.Result.EnterpriseId,
      IsPasswordResetRequested: response.Result.IsPasswordResetRequested,
    };

    this.updateIsAuthenticated(!response.Result.IsPasswordResetRequested);
    return this.currentUser;
  }

  logout(): void {
    this.currentUser = new User();
    this.router.navigate(['/']);
  }

  hasAccessToken(): boolean {
    if (this.currentUser) {
      if (this.currentUser.AccessToken) {
        return true;
      }
    }
    return false;
  }

  getDisplayName(): string {
    if (this.currentUser) {
      return this.currentUser.DisplayName;
    }
    return '';
  }
}
