import {
  ValidatorFn,
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
  UntypedFormControl,
} from '@angular/forms';

export interface StrongPasswordErrors {
  hasUpper?: boolean;
  hasLower?: boolean;
  hasSpecial?: boolean;
  hasNumber?: boolean;

  // defined by Angular form validators.
  minlength?: boolean;
  maxlength?: boolean;
}

function getControlFromForm<TControls>(
  form: UntypedFormGroup,
  fieldA: keyof TControls
) {
  const controlA = form.controls[fieldA as string];
  if (!controlA) {
    throw new Error(`Control ${fieldA as string} could not be found in form.`);
  }
  return controlA;
}

export function MatchesField<TFields>(fieldB: keyof TFields): ValidatorFn {
  return (ctrl: UntypedFormControl): ValidationErrors | null => {
    if (ctrl.pristine) {
      return null;
    }

    const controlB = getControlFromForm<TFields>(
      ctrl.parent as UntypedFormGroup,
      fieldB
    );

    return ctrl.value !== controlB.value ? { mustMatch: true } : null;
  };
}

function patternValidator(
  regex: RegExp,
  error: Partial<StrongPasswordErrors>
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}

export const StrongPassword = Validators.compose([
  patternValidator(/\d/, { hasNumber: true }),
  patternValidator(/[A-Z]/, { hasUpper: true }),
  patternValidator(/[a-z]/, { hasLower: true }),
  patternValidator(/[^A-Za-z0-9]/, { hasSpecial: true }),
  Validators.minLength(8),
  Validators.maxLength(24),
]);
