import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';

import * as CustomValidators from './validators';

interface SetPasswordControls {
  currentPassword: AbstractControl;
  newPassword: AbstractControl;
  repeatNewPassword: AbstractControl;
}

export interface PasswordFormChangeEvent {
  newPassword: string;
  repeatNewPassword: string;
}

export type SetPasswordForm = {
  controls: SetPasswordControls;
} & UntypedFormGroup;

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  @Input()
  public form: SetPasswordForm;

  @Output()
  public valuesChanged = new EventEmitter<PasswordFormChangeEvent>();

  constructor() {}

  ngOnInit() {
    this.form.addControl(
      'newPassword',
      new UntypedFormControl(
        '',
        Validators.compose([
          CustomValidators.StrongPassword,
          Validators.compose([Validators.required]),
        ])
      )
    );

    this.form.addControl(
      'repeatNewPassword',
      new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.MatchesField<SetPasswordControls>('newPassword'),
        ])
      )
    );
  }
}
