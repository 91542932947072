import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionService } from 'src/app/modules/services/permissions';
import { AuthService } from 'src/app/modules/services/auth/auth.service';
import { ApplicationOption } from 'src/app/modules/login/login.component';

@Component({
  selector: 'app-login-application-selection',
  templateUrl: './application-selection.component.html',
  styleUrls: ['./application-selection.component.scss'],
})
export class ApplicationSelectionComponent {
  @Input()
  public options: ApplicationOption[] = [];

  @Output()
  public optionSelected = new EventEmitter<ApplicationOption>();

  constructor() {}

  public onClick(option: ApplicationOption) {
    this.optionSelected.emit(option);
  }
}
