<!--The content below is only a placeholder and can be replaced.-->
<div id="page-container">
  <div id="main-content">
    <router-outlet></router-outlet>
  </div>
  <footer id="footer">
    <div id="footer-content-wrapper">
      <div class="left-col">
        <img id="capsaLogo" src="../assets/images/capsa_logo.svg" />
        <br />
        <a
          class="footer-link"
          href="//www.capsahealthcare.com"
          target="capsahealthcare"
          >CapsaHealthcare.com</a
        >
        | <a class="footer-link" href="tel:+18004376633">800.437.6633</a>
        <br />
        <a
          class="footer-link"
          href="mailto:info@capsahealthcare.com"
          target="capsainfoemail"
          >info&#64;capsahealthcare.com</a
        >
        <br />
        <p id="footer-copyright">
          &copy; {{ 'COPYRIGHT' | translate }} {{ currentYear }},
          {{ 'FOOTER_CAPSA_RIGHTS_RESERVED' | translate }}
          |
          <a class="footer-link" [href]="eulaUrl" target="capsa_eula">{{
            'FOOTER_TERMS_CONDITIONS' | translate
          }}</a>
        </p>
        <p id="footer-app-version">
          {{ appVersion }}
        </p>
      </div>
      <div class="right-col">
        <img src="../assets/images/nexsys_logo.svg" />
        <img src="../assets/images/carelink_logo.svg" />
        <img src="../assets/images/trio_logo.svg" />
      </div>
    </div>
  </footer>
</div>
