import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  SecurityQuestionAnswer,
  UserService,
} from 'src/app/modules/services/user';
import { Subscription } from 'rxjs';
import { FixedPlacementErrorMsgService } from '@capsa/services/fixed-placement-error-msg-service';
import { SecurityQuestionData } from 'src/app/modules/user/security-questions/security-questions.component';
import { UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ToasterService } from '@capsa/services/toaster/toaster.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public resetPasswordForm: UntypedFormGroup;

  private securityQuestionData: SecurityQuestionData;

  public loading: boolean;
  public passwordEmailSent: boolean;

  public get buttonEnabled(): boolean {
    return (
      this.resetPasswordForm.valid &&
      !this.loading &&
      this.securityQuestionData !== undefined
    );
  }

  private subs = new Subscription();

  constructor(
    private userService: UserService,
    private alertService: FixedPlacementErrorMsgService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.resetPasswordForm = new UntypedFormGroup({});
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onSecurityQuestionSave(securityQuestion: SecurityQuestionData) {
    this.securityQuestionData = securityQuestion;
  }

  public onPwdResetSubmit() {
    this.resetPassword();
  }

  private resetPassword() {
    this.loading = true;
    this.alertService.clear();

    const securityQuestionAnswer: SecurityQuestionAnswer = {
      SecurityAnswer: this.securityQuestionData.securityQuestionAnswer,
      SecurityQuestionId: this.securityQuestionData.securityQuestionId,
    };

    const resetPasswordSub = this.userService
      .requestPasswordReset(
        this.securityQuestionData.username,
        securityQuestionAnswer
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        () => {
          // user now logged in, redirect
          this.passwordEmailSent = true;
        },
        () => {
          this.toasterService.showError('FAILED_TO_RESET_PASSWORD');
        }
      );

    this.subs.add(resetPasswordSub);
  }
}
