<ul>
  <li>
    {{ 'SET_PASSWORD_PASSWORD_HAS_UPPER_REQUIREMENT' | translate }}
  </li>
  <li>
    {{ 'SET_PASSWORD_PASSWORD_HAS_LOWER_REQUIREMENT' | translate }}
  </li>
  <li>
    {{ 'SET_PASSWORD_PASSWORD_HAS_NUMBER_REQUIREMENT' | translate }}
  </li>
  <li>
    {{ 'SET_PASSWORD_PASSWORD_HAS_SPECIAL_REQUIREMENT' | translate }}
  </li>
  <li>
    {{ 'SET_PASSWORD_PASSWORD_MIN_LENGTH_REQUIREMENT' | translate }}
  </li>
  <li>
    {{ 'SET_PASSWORD_PASSWORD_MAX_LENGTH_REQUIREMENT' | translate }}
  </li>
</ul>
