import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/services/auth/auth.service';
import {
  UserService,
  VerifiedUserSecurityToken,
} from 'src/app/modules/services/user';
import { switchMap, finalize } from 'rxjs/operators';
import { from, Subscription } from 'rxjs';
import { User } from 'src/app/modules/services/auth/user';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import {
  UserSecurityTokenType,
  UserSecurityTokenMetadata,
} from 'src/app/modules/api/user';
import { FixedPlacementErrorMsgService } from '@capsa/services/fixed-placement-error-msg-service';

@Component({
  selector: 'app-security-code-required',
  templateUrl: './security-code-required.component.html',
  styleUrls: ['./security-code-required.component.scss'],
})
export class SecurityCodeComponent implements OnInit, OnDestroy {
  public isPageReady = false;
  public isPageLoading = true;

  @Output()
  public codeValidated = new EventEmitter<VerifiedUserSecurityToken>();

  @Input()
  public codeQueryParamName: string;

  @Input()
  public tokenType: UserSecurityTokenType;

  @Input()
  public loadingMessageTag = 'COM_LOADING';

  private subs = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private alertService: FixedPlacementErrorMsgService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.preparePage(params);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private preparePage(params) {
    if (!params.has(this.codeQueryParamName)) {
      this.setError(400);
      return;
    }

    const metadata: UserSecurityTokenMetadata =
      this.userService.parseSecurityCodeMetadata(
        params.get(this.codeQueryParamName)
      );

    // metadata was bad, so just go home after setting the error.
    if (!metadata) {
      this.setError(400);
      return;
    }

    this.location.replaceState(this.activatedRoute.routeConfig.path);
    if (
      this.tokenType === UserSecurityTokenType.ResetPassword ||
      this.tokenType === UserSecurityTokenType.Registration
    ) {
      const sub = this.userService
        .verifySecurityCode(metadata, this.tokenType)
        .pipe(
          switchMap(() => {
            return from(
              this.authService.login(metadata.username, metadata.password)
            );
          }),
          finalize(() => (this.isPageLoading = false))
        )
        .subscribe(
          (loggedInUser: User) => {
            this.isPageReady = true;
            this.codeValidated.emit({
              user: loggedInUser,
              codeMetadata: metadata,
            });
          },
          (err: HttpErrorResponse) => {
            this.setError(err.status);
          }
        );

      this.subs.add(sub);
    } else if (this.tokenType === UserSecurityTokenType.ConfirmEmailAddress) {
      const sub = this.userService
        .verifySecurityCode(metadata, this.tokenType)
        .pipe(finalize(() => (this.isPageLoading = false)))
        .subscribe(
          () => {
            this.isPageReady = true;
            // no details needed for email confirmation success
            this.codeValidated.emit(null);
          },
          (err: HttpErrorResponse) => {
            this.setError(err.status);
          }
        );

      this.subs.add(sub);
    } else {
      console.warn(
        `Handling of UserSecurityTokenType '${this.tokenType}' is not yet implemented`
      );
    }
  }

  private setError(httpStatusCode: number) {
    this.alertService.set({
      text: `VERIFY_CODE_ERROR_${httpStatusCode}`,
      severity: 'error',
    });
    this.router.navigate(['/']);
  }
}
