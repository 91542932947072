import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Record } from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_VERSION } from 'src/environments/version';

export interface AppInsightsSettingsInstance {
  instrumentationKey: string;
}

export interface AppSettingsInstance {
  apiUrl: string;
  nsightUrl: string;
  nexsysUrl: string;
  appInsights: AppInsightsSettingsInstance;
  clientId: string;
  clientSecret: string;
  eulaUrl: string;
}

const DEFAULT_APP_SETTINGS: AppSettingsInstance = {
  apiUrl: null,
  appInsights: null,
  nsightUrl: null,
  nexsysUrl: null,
  clientId: null,
  clientSecret: null,
  eulaUrl: null,
};

export const AppSettingsRecord =
  Record<AppSettingsInstance>(DEFAULT_APP_SETTINGS);

export interface AppSettingsServiceInstance {
  loaded$: Observable<boolean>;
  load(): void;
  get<TValue>(key: keyof AppSettingsInstance): TValue;
}

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService implements AppSettingsServiceInstance {
  private settingFileResourcePath = 'assets/appsettings.json';
  private settings: Record<AppSettingsInstance>;
  private loadedBehSub = new BehaviorSubject<boolean>(false);

  public loaded$ = this.loadedBehSub.asObservable();

  public get<TValue>(key: keyof AppSettingsInstance): TValue {
    return this.settings.get<TValue>(key, null);
  }

  /**
   * Not a singleton to prevent triggering the Http Interceptor constructor, which
   * depends on this service (essentially triggering a runtime circular dependency)
   */
  private httpClientNonSingleton: HttpClient;

  constructor(private handler: HttpBackend) {
    // Using "HttpBackend" prevents the HttpInterceptor from being instantiated at this point
    // and causing dependency injection issues (we don't need to intercept the call for an app settings file anyways)
    this.httpClientNonSingleton = new HttpClient(handler);
  }

  public load() {
    if (this.settings) {
      // already loaded, so just return.
      this.loadedBehSub.next(true);
      return;
    }

    const cacheBustedRoute = `${
      this.settingFileResourcePath
    }?v=${encodeURIComponent(APP_VERSION)}`;

    this.httpClientNonSingleton
      .get<AppSettingsInstance>(cacheBustedRoute)
      .pipe(map((settings) => AppSettingsRecord(settings)))
      .subscribe(
        (settings) => {
          const errors = this.getErrors(settings);
          if (errors) {
            this.loadedBehSub.error(errors);
            return;
          }

          this.settings = settings;
          this.loadedBehSub.next(true);
        },
        (error) => {
          this.loadedBehSub.error(error);
        }
      );
  }

  private getErrors(settingValues: Record<AppSettingsInstance>) {
    const errors = Object.getOwnPropertyNames(DEFAULT_APP_SETTINGS)
      .map((key) => {
        if (settingValues.get(key as any) === null) {
          return `Setting '${key}' must be specified.`;
        }
      })
      .filter((e) => !!e);

    return errors.length > 0 ? errors : null;
  }
}
