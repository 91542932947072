import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  UserService,
  SecurityQuestionResponse,
} from 'src/app/modules/services/user';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ToasterService } from '@capsa/services/toaster/toaster.service';

export interface SecurityQuestionData {
  username: string;
  securityQuestionId: number;
  securityQuestionAnswer: string;
}

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss'],
})
export class SecurityQuestionsComponent implements OnInit, OnDestroy {
  @Output()
  public valuesChanged = new EventEmitter<SecurityQuestionData>();

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public dropDownHeaderTag?: string;

  @Input()
  public readonlyUsername: boolean;

  @Input()
  public username: string;

  private subs = new Subscription();

  public questions: SecurityQuestionResponse[];

  public loading = true;

  constructor(
    private userService: UserService,
    private toasterService: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    const usernameCtrl = new UntypedFormControl(
      { value: this.username, disabled: this.readonlyUsername },
      [Validators.required]
    );
    const questionCtrl = new UntypedFormControl();
    questionCtrl.disable();

    const answerCtrl = new UntypedFormControl('', [Validators.required]);
    this.form.addControl('username', usernameCtrl);
    this.form.addControl('question', questionCtrl);
    this.form.addControl('answer', answerCtrl);

    this.subs.add(
      usernameCtrl.valueChanges.subscribe(() => {
        this.formChanged();
      })
    );

    this.subs.add(
      questionCtrl.valueChanges.subscribe(() => {
        this.formChanged();
      })
    );

    this.subs.add(
      answerCtrl.valueChanges.subscribe(() => {
        this.formChanged();
      })
    );

    this.loadSecurityQuestions();
  }

  private formChanged() {
    if (
      this.form.controls.answer.value &&
      this.form.controls.question.value &&
      this.form.controls.username.value
    ) {
      const values: SecurityQuestionData = {
        username: this.form.controls.username.value,
        securityQuestionAnswer: this.form.controls.answer.value,
        securityQuestionId: this.form.controls.question.value,
      };
      this.valuesChanged.emit(values);
    }
  }

  private loadSecurityQuestions() {
    this.loading = true;

    this.subs.add(
      this.userService
        .getSecurityQuestions()
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          (questions) => {
            this.questions = questions;
            this.form.controls.question.enable();
          },
          (error) => {
            this.toasterService.showError('ERROR_LOADING_SECURITY_QUESTIONS');
          }
        )
    );
  }
}
