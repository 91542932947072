import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { SetPasswordComponent } from 'src/app/modules/user/set-password/set-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputsComponent } from 'src/app/modules/user/set-password/inputs/inputs.component';
import { RequirementsComponent } from 'src/app/modules/user/set-password/requirements/requirements.component';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { CoreModule } from 'src/app/modules/core/core.module';
import { SecurityCodeComponent } from './security-code-required/security-code-required.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CompletePasswordResetComponent } from './complete-password-reset/complete-password-reset.component';
import { ConfirmNewEmailComponent } from 'src/app/modules/user/confirm-new-email/confirm-new-email.component';
import { SVGIconModule } from '@progress/kendo-angular-icons';

@NgModule({
  declarations: [
    UserRegistrationComponent,
    SetPasswordComponent,
    InputsComponent,
    RequirementsComponent,
    SecurityQuestionsComponent,
    SecurityCodeComponent,
    ResetPasswordComponent,
    CompletePasswordResetComponent,
    ConfirmNewEmailComponent,
  ],
  imports: [CoreModule, TranslateModule.forChild(), SVGIconModule],
  exports: [UserRegistrationComponent, ResetPasswordComponent],
})
export class UserModule {}
