import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/modules/services/auth/auth.service';
import { ApiResponse } from 'src/app/modules/api/auth';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';

enum Permissions {
  CareLinkActivity_Read = 'CareLinkActivity_Read',
  CareLinkActivity_ReadWrite = 'CareLinkActivity_ReadWrite',
  CartSearch = 'CartSearch',
  CLI_Carts_Read = 'CLI_Carts_Read',
  CLI_Carts_ReadWrite = 'CLI_Carts_ReadWrite',
  CLI_DeviceKeyValue_Read = 'CLI_DeviceKeyValue_Read',
  CLI_DeviceKeyValue_ReadWrite = 'CLI_DeviceKeyValue_ReadWrite',
  CLI_EncryptionKey_Read = 'CLI_EncryptionKey_Read',
  CLI_Facility_Read = 'CLI_Facility_Read',
  CLI_Facility_ReadWrite = 'CLI_Facility_ReadWrite',
  CLI_LightColorTheme_Read = 'CLI_LightColorTheme_Read',
  CLI_LightColorTheme_ReadWrite = 'CLI_LightColorTheme_ReadWrite',
  CLI_Messages_Read = 'CLI_Messages_Read',
  CLI_Messages_ReadWrite = 'CLI_Messages_ReadWrite',
  CLI_MessageTemplates_Read = 'CLI_MessageTemplates_Read',
  CLI_MessageTemplates_ReadWrite = 'CLI_MessageTemplates_ReadWrite',
  CLI_NotificationLightColors_Read = 'CLI_NotificationLightColors_Read',
  CLI_NotificationLightColors_ReadWrite = 'CLI_NotificationLightColors_ReadWrite',
  CLI_ProvideSecondaryWithnessAuth = 'CLI_ProvideSecondaryWithnessAuth',
  CLI_Users_Read = 'CLI_Users_Read',
  CLI_Users_ReadWrite = 'CLI_Users_ReadWrite',
  DeviceProfile_Read = 'DeviceProfile_Read',
  DeviceProfile_ReadWrite = 'DeviceProfile_ReadWrite',
  DeviceUpdate_Read = 'DeviceUpdate_Read',
  DeviceUpdate_ReadWrite = 'DeviceUpdate_ReadWrite',
  Manage_CrossEnterpiseData_Read = 'Manage_CrossEnterpiseData_Read',
  Manage_CrossEnterpiseData_ReadWrite = 'Manage_CrossEnterpiseData_ReadWrite',
  Manage_Departments_Read = 'Manage_Departments_Read',
  Manage_Departments_ReadWrite = 'Manage_Departments_ReadWrite',
  Manage_Enterprise_Read = 'Manage_Enterprise_Read',
  Manage_Enterprise_ReadWrite = 'Manage_Enterprise_ReadWrite',
  Manage_Facilities_Read = 'Manage_Facilities_Read',
  Manage_Facilities_ReadWrite = 'Manage_Facilities_ReadWrite',
  Manage_Notifications_Read = 'Manage_Notifications_Read',
  Manage_Notifications_ReadWrite = 'Manage_Notifications_ReadWrite',
  Manage_Organizations_Read = 'Manage_Organizations_Read',
  Manage_Organizations_ReadWrite = 'Manage_Organizations_ReadWrite',
  Manage_UserGroups_Read = 'Manage_UserGroups_Read',
  Manage_UserGroups_ReadWrite = 'Manage_UserGroups_ReadWrite',
  Manage_Users_Read = 'Manage_Users_Read',
  Manage_Users_ReadWrite = 'Manage_Users_ReadWrite',
  MessageQueueRequestType_Read = 'MessageQueueRequestType_Read',
  MessageQueueRequestType_ReadWrite = 'MessageQueueRequestType_ReadWrite',
  MessageQueueSubjectPreset_Read = 'MessageQueueSubjectPreset_Read',
  MessageQueueSubjectPreset_ReadWrite = 'MessageQueueSubjectPreset_ReadWrite',
  PowerLog_Read = 'PowerLog_Read',
  PowerLog_ReadWrite = 'PowerLog_ReadWrite',
  Manage_Cabinets_ReadWrite = 'Manage_Cabinets_ReadWrite',
  Manage_CabinetGroups_ReadWrite = 'Manage_CabinetGroups_ReadWrite',
}

@Injectable()
export class PermissionService {
  private getPermissionsEndpoint: string;
  public userPermissions: Set<string> = new Set<string>();

  private isReadyBehSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isReady$: Observable<boolean> = this.isReadyBehSubj.asObservable();

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {
    this.setPermissionsEndpoint();
    const isAuthSub = this.authService.isAuthenticated$.subscribe(
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.getLatestPermissions().subscribe(
            (resp) => {
              this.fillSetWithPermissions(resp.Result);
              this.isReadyBehSubj.next(true);
              isAuthSub.unsubscribe();
            },
            (error) => {
              this.authService.logout();
            }
          );
        }
      }
    );
  }

  public has(p: Permissions): boolean {
    return this.userPermissions.has(p);
  }

  /**
   * Returns true if current user has ANY of the provided permissions
   */
  public hasAny(perms: Permissions[]): boolean {
    return perms.some((p) => this.userPermissions.has(p));
  }

  private fillSetWithPermissions(permList: string[]) {
    permList.forEach((p) => {
      this.userPermissions.add(p);
    });
  }

  private setPermissionsEndpoint() {
    const apiUrl: string = this.appSettingsService.get('apiUrl');
    const separator = apiUrl.endsWith('/') ? '' : '/';
    this.getPermissionsEndpoint = `${apiUrl}${separator}user/getpermissions`;
  }

  private getLatestPermissions(): Observable<ApiResponse<string[]>> {
    return this.http.get<ApiResponse<string[]>>(this.getPermissionsEndpoint, {
      headers: {
        Authorization: 'Bearer ' + this.authService.currentUser.AccessToken,
      },
    });
  }
}
