import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginModule } from 'src/app/modules/login/login.module';
import { UserModule } from 'src/app/modules/user/user.module';
import { CoreModule } from 'src/app/modules/core/core.module';
import { HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { LOCATION_INITIALIZED } from '@angular/common';

export function appInitializerFactory(
  translateService: TranslateService,
  appSettingService: AppSettingsService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        translateService.use('en-US').subscribe(
          (translations) => {
            appSettingService.loaded$.subscribe(
              (loaded) => {
                if (loaded) {
                  resolve(null);
                }
              },
              (errors) => {
                console.error(
                  'Problem initializing application settings:',
                  errors
                );
              }
            );

            appSettingService.load();
          },
          (error) => {
            console.error(`Problem initializing language:`, error);
          }
        );
      });
    });
}

// Cache-busts translation files: https://github.com/ngx-translate/http-loader/issues/25#issuecomment-514056865
export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
    LoginModule,
    UserModule,
    NotificationModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, AppSettingsService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
