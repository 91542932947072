import { NgModule } from '@angular/core';
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy,
  Location,
} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ServicesModule } from 'src/app/modules/services/services.module';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LayoutModule } from 'src/app/modules/layout/layout.module';
import { LayoutModule as KendoLayoutModule } from '@progress/kendo-angular-layout';
import { StepperModule } from '@progress/kendo-angular-layout';
import { BearerAuthenticationInterceptor } from './interceptors';
import { AuthService } from '../services/auth/auth.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    InputsModule,
    ButtonsModule,
    DropDownListModule,
    TooltipModule,
    LayoutModule,
    ServicesModule,
    StepperModule,
    LayoutModule,
    KendoLayoutModule,
  ],
  exports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    InputsModule,
    ButtonsModule,
    DropDownListModule,
    TooltipModule,
    LayoutModule,
    ServicesModule,
    LayoutModule,
    KendoLayoutModule,
    StepperModule,
  ],
  providers: [
    BearerAuthenticationInterceptor,
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class CoreModule {}
