import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '../base';
import {
  SecurityQuestionAnswer,
  SecurityQuestionResponse,
} from 'src/app/modules/services/user';
import { ApiResponse, TranslationRequest } from 'src/app/modules/api/auth';
import { Observable } from 'rxjs';
import { AppSettingsService } from '@capsa/services/app-settings/app-settings.service';
import { tap } from 'rxjs/operators';

export enum UserSecurityTokenType {
  ConfirmEmailAddress = 'ConfirmEmailAddress',
  ResetPassword = 'ResetPassword',
  Registration = 'Registration',
}

export interface UserSecurityTokenMetadata {
  enterpriseId: number;
  username: string;
  password?: string;
  code: UserSecurityTokenType;
}

export interface UserPasswordUpdateRequest {
  EnterpriseId: number;
  Username: string;
  OldPassword: string;
  NewPassword: string;
}

export interface UserPasswordResetRequest {
  Username: string;
  SecurityQuestionId: number;
  SecurityQuestionAnswer: string;
  ReturnUrl: string;
  TranslationRequest: TranslationRequest;
}

@Injectable()
export class UserApi extends BaseApi {
  protected endpoints = {
    getSecurityQuestions: '/security-question',
    updateSecurityQuestions: '/security-question',
    verifyEmailToken: '/user/verify-token',
    updatePassword: '/user/UpdatePassword',
    resetPassword: '/user/reset-password',
    requestPasswordReset: '/user/request-password-reset',
  };

  public getSecurityQuestions() {
    return this.http
      .get<SecurityQuestionResponse[]>(
        this.buildUrl(this.endpoints.getSecurityQuestions)
      )
      .pipe(
        tap((resp) => {
          if (!resp) {
            throw new Error('Security Questions response is null');
          }
        })
      );
  }

  public verifyEmailToken(
    details: UserSecurityTokenMetadata,
    tokenType: string
  ) {
    const body = {
      Username: details.username,
      EnterpriseId: details.enterpriseId,
      Token: details.code,
      TokenType: tokenType,
    };

    return this.http.post(this.buildUrl(this.endpoints.verifyEmailToken), body);
  }

  public updateSecurityQuestions(
    questionsAndAnswers: SecurityQuestionAnswer[]
  ): Observable<ApiResponse<void>> {
    const url = this.buildUrl(this.endpoints.updateSecurityQuestions);

    const payload = {
      SecurityQuestionAnswers: questionsAndAnswers,
    };

    return this.http.post<ApiResponse<void>>(url, payload);
  }

  public updatePassword(
    request: UserPasswordUpdateRequest
  ): Observable<ApiResponse<void>> {
    const url = this.buildUrl(this.endpoints.updatePassword);

    return this.http.post<ApiResponse<void>>(url, request);
  }

  public requestPasswordReset(
    request: UserPasswordResetRequest
  ): Observable<void> {
    const url = this.buildUrl(this.endpoints.requestPasswordReset);
    return this.http.post<void>(url, request);
  }

  constructor(
    protected http: HttpClient,
    protected appSettingsService: AppSettingsService
  ) {
    super(http, appSettingsService);
  }
}
