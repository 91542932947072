<ng-container [formGroup]="form">
  <div class="form-row" *ngIf="requireCurrentPassword">
    <div class="form-group col">
      <label>{{ 'SET_PASSWORD_CURRENT_PASSWORD_HEADER' | translate }}</label>
      <input
        kendoTextBox
        type="password"
        formControlName="currentPassword"
        required
        class="form-control"
        data-testid="pwdCurrentPassword"
        autocomplete="current-password"
      />
    </div>
  </div>

  <div>
    <ng-template #reqTemplate let-anchor>
      <app-set-password-requirements></app-set-password-requirements>
    </ng-template>
    <div class="new-password-header-wrapper">
      <div>
        <label class="input-header"
          >{{ 'SET_PASSWORD_NEW_PASSWORD_HEADER' | translate }}
        </label>
      </div>
      <div
        kendoTooltip
        position="right"
        [tooltipTemplate]="reqTemplate"
        [filter]="'kendo-svg-icon'"
      >
        <kendo-svg-icon
          [icon]="icons.questionCircleIcon"
          themeColor="info"
        ></kendo-svg-icon>
      </div>
      <div>
        <label class="input-error" *ngIf="newPasswordErrorTag">
          * {{ newPasswordErrorTag | translate }}
        </label>
      </div>
    </div>

    <input
      kendoTextBox
      type="password"
      formControlName="newPassword"
      required
      class="form-control"
      data-testid="pwdNewPassword"
      autocomplete="new-password"
    />
  </div>

  <div>
    <div class="repeat-password-header-wrapper">
      <div>
        <label class="input-header"
          >{{ 'SET_PASSWORD_RETYPE_NEW_PASSWORD_HEADER' | translate }}
        </label>
      </div>
      <div>
        <label class="input-error" *ngIf="repeatPasswordErrorTag">
          * {{ repeatPasswordErrorTag | translate }}
        </label>
      </div>
    </div>
    <input
      kendoTextBox
      type="password"
      required
      formControlName="repeatNewPassword"
      class="form-control"
      data-testid="pwdConfirmNewPassword"
      autocomplete="new-password"
    />
  </div>
</ng-container>
