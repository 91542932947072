<app-content-wrapper [titleTag]="'NEW_USER_REGISTRATION'">
  <app-security-code-required
    [codeQueryParamName]="emailConfirmQueryParamName"
    [tokenType]="registrationTokenType"
    (codeValidated)="onSecurityCodeVerified($event)"
  >
    <kendo-stepper
      [steps]="steps"
      [stepType]="stepType"
      [(currentStep)]="current"
      [style.width.px]="350"
      [linear]="linear"
    >
    </kendo-stepper>

    <ng-container *ngIf="registrationDetails">
      <form [formGroup]="userRegistrationForm">
        <div class="step-content">
          <div [hidden]="current !== 0">
            <app-security-questions
              [readonlyUsername]="true"
              [username]="registrationDetails.username"
              [form]="userRegistrationForm"
              (valuesChanged)="onSecurityQuestionUpdate($event)"
            ></app-security-questions>
          </div>
          <div [hidden]="current !== 1">
            <app-set-password
              [form]="userRegistrationForm"
              (valuesChanged)="onPasswordUpdate($event)"
            ></app-set-password>
          </div>
          <div class="form-group">
            <br />
            <button
              [disabled]="!canClickButton"
              (click)="onButtonClicked()"
              kendoButton
              [primary]="true"
              data-testid="btnCompleteRegistration"
            >
              <ng-container *ngIf="current === 0">
                {{ 'COM_NEXT' | translate }}
              </ng-container>
              <ng-container *ngIf="registering">
                {{ 'REGISTERING' | translate }}
              </ng-container>
              <ng-container *ngIf="redirecting">
                {{ 'LOGIN_PAGE_REDIRECTING' | translate }}
              </ng-container>
              <ng-container
                *ngIf="!redirecting && !registering && current === 1"
              >
                {{ 'COMPLETE_REGISTRATION' | translate }}
              </ng-container>
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </app-security-code-required>
</app-content-wrapper>
